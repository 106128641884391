<template>
  <v-container fluid>
    <div class="">
      <v-flex class="py-2 px-3 tableHeader">Users</v-flex>
      <v-flex
        class="tableSearch px-4 pt-2"
        style="display: flex; align-items: center"
        v-if="false"
      >
        <v-layout row wrap xs12>
          <v-form style="width: 100%" class="px-3" @submit.prevent="searchData">
            <v-row align="center">
              <v-col cols="12" md="5">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.search')"
                  v-model="search"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-select
                  :items="searchable_items"
                  :item-text="'text'"
                  :item-value="'search'"
                  v-model="search_field"
                  :label="$vuetify.lang.t('$vuetify.search_from_column')"
                  disable-initial-sort
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="2">
                <div style="display: flex; align-items: center">
                  <v-btn
                    type="submit"
                    outlined
                    color="secondary"
                    class="caption ma-0"
                    block
                    >{{ $vuetify.lang.t("$vuetify.search") }}</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-layout>
      </v-flex>
    </div>
    <v-data-table
      item-key="id"
      height="530"
      class="elevation-1 data-table"
      :headers="tableHeader"
      :items="usersList"
      :loading="loading"
      :options="paginationOptions"
      :server-items-length="totalCount"
      loading-text="Loading Please Wait"
      no-results-text="No Data found...!"
      no-data-text="No Data available"
      @pagination="paginationChanges"
      @click:row="rowClicked"
    >
      <template v-slot:[`item.id`]="{ item }">
        <v-btn icon class="secondary mr-3">
          <v-icon color="#fff" @click="editUser(item.id)">mdi-pencil</v-icon>
        </v-btn>

        <v-btn icon class="error">
          <v-icon color="#fff" @click="deleteUser(item.id)">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" maxWidth="600px">
      <AddUser
        :key="dialog"
        :edit="edit"
        :employee_id="employee_id"
        @close-model="closeModal"
        @refresh="fetchUsers"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { get_user_role } from "@/helpers";
import AddUser from "@/components/add-user/add-user";
export default {
  name: "system-admin-users",
  components: { AddUser },
  data() {
    return {
      users: [],
      loading: false,
      totalCount: 0,
      currentPage: 1,
      limit: 10,
      search: "",
      search_field: "first_name",
      // add-user
      dialog: false,
      edit: false,
      employee_id: "",
    };
  },
  computed: {
    paginationOptions() {
      return { page: this.currentPage, itemsPerPage: this.limit };
    },
    tableHeader() {
      return [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "Email",
          align: "start",
          value: "email",
        },
        {
          text: "Phone",
          align: "start",
          value: "phone",
        },
        {
          text: "Company Name",
          align: "start",
          value: "company_name",
        },
        {
          text: "User Role",
          align: "start",
          value: "role",
        },
        {
          text: "Action",
          align: "start",
          value: "id",
        },
      ];
    },
    usersList() {
      return this.users.map((user) => {
        const { _id, full_name, email, phone, role, company } = user;
        return {
          id: _id,
          name: full_name,
          email,
          phone,
          role: get_user_role(role),
          company_name: company?.name ?? "n/a",
        };
      });
    },
    searchable_items() {
      return [
        {
          search: "first_name",
          text: "Name",
        },
      ];
    },
    whereHas() {
      let whereHas = null;
      let like = null;
      if (this.search != "") {
        let fields = this.search_field.split(",");

        if (fields.length > 1) {
          whereHas = {
            key: fields[0],
            value: fields[1] + "," + this.search,
          };
          return JSON.stringify(whereHas);
        } else {
          like = {
            key: this.search_field,
            value: this.search,
          };
          return JSON.stringify(like);
        }
      } else {
        return null;
      }
    },
  },
  methods: {
    rowClicked(data) {
      console.log("SOME ROW CLICKED", data);
    },
    paginationChanges(options) {
      const { page, itemsPerPage } = options;
      if (page !== this.currentPage || itemsPerPage !== this.limit) {
        this.currentPage = page;
        this.limit = itemsPerPage;
        this.fetchUsers();
      }
    },
    async fetchUsers() {
      try {
        this.loading = true;
        const { currentPage, limit } = this;
        let url = `/user?w=company&p=${currentPage}&limit=${limit}`;

        if (this.whereHas) {
          let key = "whereHas";
          url += `&${key}=${this.whereHas}`;
        }

        const resp = await this.axios.get(url);
        const { data, count } = resp.data;
        this.users = data;
        this.totalCount = count;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    searchData() {
      this.currentPage = 1;
      this.fetchUsers();
    },
    closeModal() {
      this.dialog = false;
      (this.edit = false), (this.employee_id = "");
    },
    editUser(id) {
      this.edit = true;
      this.employee_id = id;
      this.dialog = true;
    },
    async deleteUser(id) {
      try {
        let response = await this.axios.delete(`/user/${id}`);
        if (response.status === 200) {
          this.$notify({
            group: "success",
            type: "success",
            title: "Success",
            text: "Company deleted successfully!",
          });
          this.fetchUsers();
        } else {
          this.$notify({
            group: "backend-error",
            type: "error",
            title: "Validation Error",
            text: "Something went wrong. Please try again later.",
          });
        }
      } catch (error) {
        this.$notify({
          group: "backend-error",
          type: "error",
          title: "Validation Error",
          text: "Something went wrong. Please try again later.",
        });
        // this.error_alert = true;
        // this.error_message = "Something went wrong ";
      }
    },
  },
  created() {
    this.fetchUsers();
  },
};
</script>

<style lang="scss" scoped>
.tableHeader {
  letter-spacing: 1px;
  font-weight: bold;
  background-color: #eaeef2;
  border: 1px solid #e6e6f1;
  color: #3dade5;
}
.tableSearch {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  min-height: 70px;
}
.table-row {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  td {
    color: #2f3242;
  }
}

.data-table {
  &::v-deep .v-data-table-header__icon {
    display: none;
  }
}
</style>
