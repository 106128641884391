<template>
  <v-card :loading="loading">
    <v-card-title>
      {{ $vuetify.lang.t(`$vuetify.${edit ? "edit" : "add"}`) }}
      {{ $vuetify.lang.t("$vuetify.user") }}
    </v-card-title>
    <v-card-text>
      <v-form
        class="mt-3 text-md-center"
        v-on:submit.prevent="AddNewEmployee"
        v-model="isValid"
      >
        <v-text-field
          v-model="employee.first_name"
          :label="$vuetify.lang.t('$vuetify.first_name')"
          :rules="[rules.required]"
          required
        ></v-text-field>
        <v-text-field
          v-model="employee.last_name"
          :label="$vuetify.lang.t('$vuetify.last_name')"
          :rules="[rules.required]"
          required
        ></v-text-field>
        <v-text-field
          v-model="employee.email"
          :rules="[rules.required, rules.email]"
          required
          :label="$vuetify.lang.t('$vuetify.email')"
          name="some-random-email"
        ></v-text-field>
        <v-text-field
          v-model="employee.phone"
          :rules="[rules.required]"
          required
          :label="$vuetify.lang.t('$vuetify.contact')"
          type="number"
        ></v-text-field>
        <v-text-field
          v-model="employee.password"
          name="some-random-password"
          :required="edit ? false : true"
          :rules="edit ? [] : [rules.required]"
          :label="$vuetify.lang.t('$vuetify.password')"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-btn :loading="loading" class="secondary" type="submit">{{
          $vuetify.lang.t("$vuetify.save")
        }}</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { validateEmail } from "@/helpers";

import { mapState } from "vuex";
export default {
  name: "add-company-user",
  props: {
    edit: { type: Boolean, default: false },
    employee_id: { type: [String, Number] },
  },
  data() {
    return {
      employee: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
      },
      rules: {
        required: (v) => !!v || "Field is required",
        email: (v) => !!validateEmail(v) || "Email is invalid",
      },
      showPassword: false,
      success_alert: false,
      error_alert: false,
      error_message: "",
      loading: false,
      isValid: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    if (this.employee_id) this.fetchEmployee();
  },
  methods: {
    close() {
      this.$emit("close-model");
      this.$emit("refresh");
      this.loading = false;
    },
    async AddNewEmployee() {
      try {
        if (!this.isValid) {
          this.error_alert = true;
          this.error_message = "Please fill all the required fields";
          return;
        }

        this.loading = true;
        this.employee.company_id = this.user.company_id;

        if (this.employee._id) {
          this.editEmployee();
          return;
        }
        this.employee.role = 3;
        this.loading = true;
        await this.axios.post("/user", this.employee);
        this.close();
      } catch (error) {
        this.loading = false;
        this.error_alert = true;
        this.error_message = "Something went wrong ";
      }
    },
    async editEmployee() {
      try {
        await this.axios.put(`/user/${this.employee_id}`, this.employee);
        this.close();
      } catch (error) {
        this.loading = false;
        this.error_alert = true;
        this.error_message = "Something went wrong ";
      }
    },
    async fetchEmployee() {
      try {
        this.loading = true;
        const resp = await this.axios.get(
          `/user/${this.employee_id}?w=company`
        );
        this.employee = resp.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.error_alert = true;
        this.error_message = "Something went wrong ";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
